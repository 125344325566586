import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faCopy,
	faCalendarAlt as farCalendarAlt,
	faCheckSquare as farCheckSquare,
	faCircle as farCircle,
	faClock as farClock,
	faFile as farFile,
	faImage as farImage,
	faImages as farImages,
	faSquare as farSquare
} from '@fortawesome/free-regular-svg-icons'
import {
	faAlignCenter,
	faAlignJustify,
	faAlignLeft,
	faAlignRight,
	faArrowAltCircleRight,
	faArrowLeft,
	faArrowRightArrowLeft,
	faArrowsAlt,
	faArrowsAltV,
	faAsterisk,
	faBars,
	faBarsStaggered,
	faBold,
	faBoxArchive,
	faBug,
	faCalendarAlt,
	faCamera,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faChartArea,
	faChartBar,
	faChartColumn,
	faChartGantt,
	faChartLine,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleNotch,
	faClipboard,
	faClock,
	faClone,
	faCode,
	faCog,
	faComment,
	faCompress,
	faCompressAlt,
	faDollarSign,
	faDownload,
	faEllipsisH,
	faEllipsisV,
	faEnvelope,
	faEraser,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faExpand,
	faExpandAlt,
	faExternalLinkAlt,
	faEye,
	faEyeSlash,
	faFile,
	faFileDownload,
	faFileExport,
	faFileImport,
	faFileLines,
	faFilePdf,
	faFilter,
	faFloppyDisk,
	faFolderOpen,
	faFont,
	faGlobe,
	faGripVertical,
	faHeading,
	faHistory,
	faImage,
	faIndent,
	faInfo,
	faInfoCircle,
	faItalic,
	faKey,
	faLanguage,
	faLink,
	faListOl,
	faListUl,
	faMagic, faMagnifyingGlass, faMagnifyingGlassMinus, faMagnifyingGlassPlus,
	faMicrochip,
	faMinus,
	faMinusCircle,
	faMousePointer,
	faOutdent,
	faPaintBrush,
	faPalette,
	faPaperclip,
	faPaperPlane,
	faParagraph,
	faPen,
	faPhoneAlt,
	faPlay,
	faPlus,
	faPlusCircle,
	faPrint,
	faQrcode,
	faQuestion,
	faQuestionCircle,
	faQuoteLeft,
	faRedo,
	faRemoveFormat,
	faReply,
	faRobot,
	faRotateLeft,
	faSave,
	faSearch,
	faShare,
	faSort,
	faSortDown,
	faSortUp,
	faSquare,
	faStar,
	faStrikethrough,
	faSubscript,
	faSuperscript,
	faSync,
	faTable,
	faTableCellsLarge,
	faTableList,
	faTimes,
	faTimesCircle,
	faTint,
	faTrash,
	faUnderline,
	faUndo,
	faUpload,
	faUser
} from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons/faArrowUpRightDots'
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie'

library.add(
	faAlignCenter,
	faAlignJustify,
	faAlignLeft,
	faAlignRight,
	faArrowAltCircleRight,
	faArrowLeft,
	faArrowRight,
	faArrowRightArrowLeft,
	faArrowsAlt,
	faArrowsAltV,
	faArrowUpRightDots,
	faAsterisk,
	faBars,
	faBarsStaggered,
	faBold,
	faBoxArchive,
	faBug,
	faCalendarAlt,
	faCamera,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faChartArea,
	faChartBar,
	faChartColumn,
	faChartGantt,
	faChartLine,
	faChartPie,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleNotch,
	faClipboard, faClock,
	faClone,
	faCode,
	faCog,
	faComment,
	faCompress,
	faCompressAlt,
	faCopy,
	faDollarSign,
	faDownload,
	faEllipsisH,
	faEllipsisV,
	faEnvelope,
	faEraser,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faExpand,
	faExpandAlt,
	faExternalLinkAlt,
	faEye,
	faEyeSlash,
	faFile,
	faFileDownload,
	faFileExport,
	faFileImport,
	faFileLines,
	faFilePdf,
	faFilter,
	faFloppyDisk,
	faFolderOpen,
	faFont,
	faGlobe,
	faGripVertical,
	faHeading,
	faHistory,
	faImage,
	faIndent,
	faInfo,
	faInfoCircle,
	faItalic,
	faKey,
	faLanguage,
	faLink,
	faListOl,
	faListUl,
	faMagnifyingGlass,
	faMagnifyingGlassPlus,
	faMagnifyingGlassMinus,
	faMagic,
	faMicrochip,
	faMinus,
	faMinusCircle,
	faMousePointer,
	faOutdent,
	faPaintBrush,
	faPalette,
	faPaperclip,
	faPaperPlane,
	faParagraph,
	faPen,
	faPhoneAlt,
	faPlay,
	faPlus,
	faPlusCircle,
	faPrint,
	faQrcode,
	faQuestion,
	faQuestionCircle,
	faQuoteLeft,
	farCalendarAlt,
	farCheckSquare,
	farCircle,
	farClock,
	faRedo,
	faRemoveFormat,
	faReply,
	farFile,
	farImage,
	farImages,
	faRobot,
	faRotateLeft,
	farSquare,
	faSave,
	faSearch,
	faShare,
	faSort,
	faSortDown,
	faSortUp,
	faSquare,
	faStar,
	faStrikethrough,
	faSubscript,
	faSuperscript,
	faSync,
	faTable,
	faTableCellsLarge,
	faTableList,
	faTimes,
	faTimesCircle,
	faTint,
	faTrash,
	faUnderline,
	faUndo,
	faUpload,
	faUser
)
